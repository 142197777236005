<template>
<div class="l-container-content bg-white">
    <div class="row">
        <div class="col-6">
            <h3>All Banks</h3>
        </div>
        
    </div>
    <div class="row">
        <div class="col-10">
           
            <div class="input inline">
                    <input v-on:keyup="searchRecord" type="text" placeholder="Search Invoice..." v-model="search_match">
                </div>
        </div>
        <div class="col-2 text-right">
            <button class="button primary" @click="applyTicketType">Add Invoice Type</button>
        </div>
    </div>
    <div class="row">
        <loader v-show="loading" />
        <vuetable ref="vuetable" api-url="https://api.v1.interactive.madfun.com/v1/api/payments/view/invoice/type" :fields="columns" :sort-order="sortOrder" track-by="typeId" :append-params="moreParams" :per-page="10" @vuetable:pagination-data="onPaginationData" @vuetable:loading="onLoading" @vuetable:loaded="onLoaded">
            <div slot="status" slot-scope="props">
                <div><strong>{{props.rowData.status | formatStatus}}</strong></div>
            </div>
        </vuetable>
        <div class="vuetable-pagination ui basic segment grid">
            <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

            <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
        </div>
    </div>
    <modal name="example" :width="500" :height="320" :adaptive="true">
        <div class="row">
            <div class="col">

                <div class="l-form row">
                    <div class="col">
                        <h3>Invoice Type</h3>
                        <input type="text" v-model="invoiceType" placeholder="e.g  Event Organizer Invoice">
                    </div>
                    <div class="col">
                        <h3>Description</h3>
                        <textarea v-model="invoiceDesc" rows="4" cols="50"></textarea>
                    </div>
                   
                    <div class="col" v-show="errorcreateStatus">
                        <div >
                            <small class="text-error">{{errorsMessage}}</small>
                        </div>
                    </div>

                    <div class="col">
                        <button class="button primary" id="createType" @click="createInvoiceType()">Create</button>

                    </div>
                </div>
            </div>
        </div>
    </modal>
</div>
</template>

<style>
td.vuetable-slot {
    overflow: visible !important;
}
</style>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'
import store from "../store";
import $ from "jquery";
export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        loader,
        VModal
    },
    name: "ticketTypes",

    data() {
        return {
            loading: true,
            errorcreateStatus: false,
            successcreateStatus: false,
            invoiceType:"",
            invoiceDesc:"",
            errorsMessage: "",
            successMessage: "",
            type: "",
            loadRecord: false,
            selected_data: {},
            columns: [{
                    name: "invoice_type",
                    title: "Invoice Type",
                    sortField: "invoice_type",
                    titleClass: "",
                    dataClass: ""
                },
                
                {
                    name: "status",
                    title: "Status",
                    sortField: "status",
                    titleClass: "",
                    dataClass: ""
                },
                {
                    name: "description",
                    title: "Description",
                    sortField: "description",
                    titleClass: "",
                    dataClass: ""
                },
                {
                    name: "created",
                    title: "Date",
                    sortField: "created",
                    callback: "createdAt"
                }
            ],
            range: "",
            search_match: "",
            loadOnStart: true,
            sortOrder: [{
                field: "created",
                direction: "desc"
            }],
            is_partial_resulting: false,

            moreParams: {
                start: "",
                filter: "",
                end: ""
            }
        };
    },

    mounted() {
        if (!this.$cookies.get("key")) {
            this.$router.push({
                name: 'login'
            });
        }
        var vm = this;
        if (vm.$cookies.get("role_id") != 1) {
            vm.$router.push({
                name: "dashboard"
            });
        }

    },

    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.refresh();
        },
        range() {
            var start = this.range[0];
            var end = this.range[1];
            start = moment(start).format("YYYY-MM-DD");
            end = moment(end).format("YYYY-MM-DD");
            this.moreParams.start = start;
            this.moreParams.end = end;
            this.$refs.vuetable.refresh();
        }
    },

    methods: {
        applyTicketType() {
            this.$modal.show('example')

        },
        createInvoiceType() {
            $("#createType").html('<i class="fa fa-spinner fa-spin"></i> Loading...');
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/payments/add/invoice/type",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    invoice_type: vm.invoiceType,
                    description: vm.invoiceDesc
                }),
                success: function (response, status, jQxhr) {
                    if (response.data.code != 200) {
                        $("#createType").text("Create");
                        vm.errorcreateStatus = true;
                        vm.successcreateStatus = false;
                        vm.errorsMessage = response.data.message
                    } else {
                        vm.errorcreateStatus = false;
                        vm.successcreateStatus = true;
                        vm.successMessage = response.data.message
                        vm.$refs.vuetable.refresh();
                        vm.$modal.hide('example')
                        $("#createType").text("Create");
                    }

                },
                error: function (jQxhr, status, error) {
                    $("#createType").text("Create");
                    vm.errorcreateStatus = true;
                    vm.successcreateStatus = false;
                    vm.errorsMessage = jQxhr.responseJSON.statusDescription;
                }
            });
        },

        dateFilter: function (start, end) {
            var vm = this;

            vm.start = start;
            vm.end = end;

            vm.moreParams.start = start;
            vm.moreParams.end = end;

            this.$refs.vuetable.refresh();

            console.log("start date filter", start);
            console.log("end date filter ", end);
        },
        createdAt: function (value) {
            if (!value) {
                return "-";
            } else {
                return moment(value).format("h:mm a,DD MMM YYYY");
            }
        },
        searchRecord: function () {
            this.moreParams.filter = this.search_match;
            this.$refs.vuetable.refresh();
        },

        dataManager(sortOrder, pagination) {
            if (this.data.length < 1) return;

            let local = this.data;

            // sortOrder can be empty, so we have to check for that as well
            if (sortOrder.length > 0) {
                console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                );
            }

            pagination = this.$refs.vuetable.makePagination(
                local.length,
                this.perPage
            );
            console.log("pagination:", pagination);
            let from = pagination.from - 1;
            let to = from + this.perPage;

            return {
                pagination: pagination,
                data: _.slice(local, from, to)
            };
        },

        onPaginationData(paginationData) {
            this.$refs.paginationInfo.setPaginationData(paginationData);
            this.$refs.pagination.setPaginationData(paginationData);
        },

        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        onLoading() {
            this.loading = true;
            console.log("loading... show your spinner here");
        },
        onLoaded() {
            this.loading = false;
            console.log("loaded! .. hide your spinner here");
            $(".ui.dropdown").dropdown();
        }
    },
    filters: {
        formatStatus(value) {
            if (value != null) {
                if (value == 1) {
                    return "Active"
                } else {
                    return "Inactive"
                }

            }
            return ""
        }
    }
};
</script>
